import React, { useState } from "react";
import Axios from "axios";
export default function Signup() {
  let [error, setError] = useState("");
  let [user, setUser] = useState({
    first_name: "",
    last_name: "",
    age: 0,
    email: "",
    password: "",
  });
  function getUserData(e) {
    let myUser = { ...user };
    myUser[e.target.name] = e.target.value;
    setUser(myUser);
    console.log(myUser);
  }
  async function submitRegisterFrom(event) {
    event.preventDefault();
    let { data } = await Axios.post("http://localhost:3000/signup".user);
    if (data.message === "success") {
    } else {
      setError(data.message);
    }
  }
  return (
    <>
      <div className="w-75 mx-auto">
        <h2>Register Now </h2>
        {error.length > 0 ? (
          <div className="alert alert-danger">{error}</div>
        ) : (
          ""
        )}
        <form onSubmit={submitRegisterFrom}>
          <label htmlFor="first_name">First Name</label>
          <input
            onChange={getUserData}
            className="form-control mb-2"
            type="text"
            id="first_name"
            name="first_name"
          />
          <label htmlFor="last_name">Last Name</label>
          <input
            onChange={getUserData}
            className="form-control mb-2"
            type="text"
            id="last_name"
            name="last_name"
          />
          <label htmlFor="email">Email</label>
          <input
            onChange={getUserData}
            className="form-control mb-2"
            type="email"
            id="email"
            name="email"
          />
          <label htmlFor="password">Password</label>
          <input
            onChange={getUserData}
            className="form-control mb-2"
            type="password"
            id="password"
            name="password"
          />
          <label htmlFor="age">Age </label>
          <input
            onChange={getUserData}
            className="form-control mb-2"
            type="number"
            id="age"
            name="age"
          />
          <button type="submit" className="btn btn-outline-info">
            SignUp
          </button>
        </form>
      </div>
    </>
  );
}
