import "./App.css";
import Navbar from "./Navbar";
import { Routes, Route } from "react-router-dom";
import Home from "./Home";
import Tv from "./Tv";
import Movies from "./Movies";
import NotFound from "./NotFound";
import People from "./People";
import Login from "./Login";
import Signup from "./Signup";
import Footer from "./Footer";
import MovieDetils from "./MovieDetils";

function App() {
  return (
    <div>
      <Navbar />
      <div className="container">
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="home" element={<Home />}></Route>
          <Route path="movies" element={<Movies />}></Route>
          <Route path={"moviedetils"} element={<MovieDetils />}>
            <Route path={":id"} element={<MovieDetils />}></Route>
          </Route>
          <Route path="tv" element={<Tv />}></Route>
          <Route path="people" element={<People />}></Route>
          <Route path="login" element={<Login />}></Route>
          <Route path="signup" element={<Signup />}></Route>
          <Route path="*" element={<NotFound />}></Route>
        </Routes>
      </div>
      <Footer />
    </div>
  );
}

export default App;
